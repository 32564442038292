@tailwind base;
@tailwind components;
@tailwind utilities;

.title-bar {
  @apply flex items-center justify-between text-white;
  -webkit-app-region: drag;
  user-select: none;
}

.title {
  -webkit-app-region: drag;
  font-family: Homenaje, sans-serif;
  font-size: 20px;
}

.logo {
  width: 28px;
  height: 28px;
  margin: 6px 0px 6px 13px;
  /* padding: 6px 8px; */
}

.window-controls {
  @apply flex;
  -webkit-app-region: no-drag;
}

.window-control-button {
  @apply cursor-pointer bg-transparent text-white;
  -webkit-app-region: no-drag;
  padding: 4px;
  margin: 0;
  border: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
}

.window-control-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.window-control-button:active {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Specific styles for the close button */
.window-control-button:last-child:hover {
  background-color: #e81123;
}
