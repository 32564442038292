@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-text {
  font-family: 'Homenaje', sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  @apply bg-primary text-primary;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.light .App-header {
  @apply bg-light-primary text-light-primary;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.App-page {
  @apply bg-primary text-primary;
  padding: 1.5rem;
  overflow-y: auto;
  height: 100vh;
  width: 100%;
}

.light .App-page {
  @apply bg-light-primary text-light-primary;
}

.App-titlebar {
  height: calc(100vh - 40px) !important;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: ease-in-out forwards;
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 1s;
  animation-fill-mode: ease-in-out forwards;
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: ease-in-out forwards;
}

.card {
  transition: all 0.3s;
  position: relative;
  z-index: 1;
}

.card-expanded {
  position: fixed;
  top: 10px;
  left: 65px;
  right: 10px;
  bottom: 10px;
  background-color: white;
  z-index: 100;
  transition: all 0.3s;
}

.card-expanded.mobile {
  left: 10px;
  top: 67px;
}

.card-expanded.electron-app {
  top: 50px;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

.snackbar-landing {
  top: 80px !important;
}

.snackbar-electron {
  top: 50px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.card-container {
  margin-bottom: 16px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.feature-card {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 1024px) {
  .feature-card {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

/* Optional: Add a custom breakpoint for extra large screens */
@media (min-width: 1280px) {
  .feature-card {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.carousel-root {
  width: 100% !important;
  min-width: 200px;
}

/* LandingNavigation.css */
.slide-down-enter {
  transform: translateY(-100%);
}

.slide-down-enter-active {
  transform: translateY(0%);
  transition: transform 300ms ease-in-out;
}

.slide-down-exit {
  transform: translateY(0%);
}

.slide-down-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}


.flyout {
  position: fixed;
  bottom: 0;
  right: -100%;
  width: 400px;
  height: 100%;
  @apply bg-gray-800;
  padding: 20px;
  transition: right 0.3s ease-in-out;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
}

.light .flyout {
  @apply bg-light-accent;
}

.flyout.open {
  right: 0;
}

.flyout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.flyout-header h3 {
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.flyout-body {
  flex-grow: 1;
}

.flex.justify-between.items-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .bg-accent {
  @apply bg-gray-800 text-white py-2 px-4 rounded mb-4;
  cursor: pointer;
} */

.close-button {
  font-size: 20px;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;
}

.login-page,
.signup-page {
  @apply bg-primary h-screen flex items-center justify-center;
}

.login-card,
.signup-card {
  width: 100%;
  max-width: 400px;
}

.light .login-page,
.light .signup-page {
  @apply bg-light-primary;
}

.error-page {
  @apply flex items-center justify-center min-h-screen bg-primary;
}

.light .error-page {
  @apply bg-light-primary;
}

.error-container {
  text-align: center;
}

.error-title {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #fff;
}

.error-subtitle {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #fff;
}

.error-description {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #ccc;
}

.error-btn {
  @apply inline-block py-3 px-6 border-none rounded text-white text-base font-semibold no-underline cursor-pointer transition-colors ease-in-out duration-200 bg-secondary;
}

.error-btn:hover {
  @apply bg-accent;
}

.loading-screen {
  @apply flex flex-col items-center justify-center min-h-screen bg-primary;
}

.light .loading-screen {
  @apply bg-light-primary;
}

.loading-logo-container {
  text-align: center;
  margin-bottom: 2rem;
}

.loading-logo {
  max-width: 100%;
  height: auto;
}

.loading-text-container {
  text-align: center;
}

.loading-title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #fff;
  font-family: Homenaje, sans-serif;
}
