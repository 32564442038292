  .tutorial-backdrop {
    position: absolute;
    overflow: hidden;
  }
  
  .tutorial-cutout::before,
  .tutorial-cutout::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    pointer-events: none;
  }
  
  .tutorial-cutout::before {
    top: 0;
    left: 100%;
  }
  
  .tutorial-cutout::after {
    top: 100%;
    left: 0;
  }
  